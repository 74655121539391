import client from '../feathers'

const getConnectedOrganizations = async () => {
  const { connected_organizations_a, connected_organizations_b, users } = await client.service('organizations').get(0)
  const connected_organizations = connected_organizations_a
    .concat(connected_organizations_b)
    .sort((o1, o2) => {
      if (o1.name.toLowerCase() < o2.name.toLowerCase()) return -1
      if (o1.name.toLowerCase() > o2.name.toLowerCase()) return 1
      return  0
    })

  return { 
    connectedOrganizations: (
      connected_organizations.map(org => ({
        ...org, 
        reference: org.connections.reference,
        customerReference: org.connections.customerReference,
        locationAddress: [org.streetNumber, org.postalCode, org.city].join(', ') 
      }))
    ) 
  }
}

const getAnonAddresses = async (user) => {
  if (!['carrier', 'terminal'].includes(user.organizationRole)) {
    let connections = await window.walletApi.getConnections();
    // add the public address too
    connections.push({ local: { address: user.organizationAddress }});
    // get the addresses in 'local' and filter out the undefined ones
    return connections.map(c => c.local.address).filter(c => !!c);
  } else {
    return [];
  }
}

const findOrgName = (orgAddress, connections, connectedOrganizations) => {
  let party;
  const connection = connections.find(c => c.remote.address === orgAddress);
  if (connection) {
    party = connectedOrganizations.connectedOrganizations.find(org => org.reference === connection.ref);
  } 
  return party ? party.name : undefined
}

export { getConnectedOrganizations, getAnonAddresses, findOrgName }