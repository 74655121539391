import React, { useState }  from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react'
import { mdiMicrosoftExcel as ExcelIcon } from '@mdi/js';
import FilterListIcon from '@material-ui/icons/FilterList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { saveAs } from 'file-saver';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Http } from '../axios';
import { getExcelWorkbook } from '../util/excel';
import { getPincode, filterSelectedReleases, filterNonblockedReleases, filterAssignLocationsReleases } from '../util/pincode';
import useWalletError from '../hooks/useWalletError';
import useErrorDrawer from '../hooks/useErrorDrawer';
import useAuth from '../hooks/useAuth';
import useIntl from '../hooks/useIntl';
import useConfirmDialog from '../hooks/useConfirmDialog';
import useSecurePickupDialog from '../hooks/useSecurePickupDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { CreateReleaseButton } from './carrier-ui/CreateReleaseButton';
import { fetchTermsAndConditions } from '../util/terms-and-conditions';
import { isTokenValid } from '../util/token';
import { checkAssignLocations } from '../services/releases';
import BulkOperationsButton from './bulk-operations/BulkOperationsButtons';
import RequestForReleaseButton from './request-for-release/RequestForReleaseButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useFlatExcel from '../hooks/useFlatExcel';
import useSentry from '../hooks/useSentry';
import NMoTDialog from './NMoT/NMoTDialog';

const useStyles = makeStyles(() => ({
  icon_hover: {
    '&:hover': {
      color: '#cda24d',
    },
  },
}));

const DownloadButtons = ({
  onFilterClick,
  filter = {},
  search,
  selectedContainerAddresses,
  refreshBills,
  doTransfer,
  anonAddresses,
  doGrouping
}) => {

  const classes = useStyles();
  const { translate } = useIntl();
  const onWalletError = useWalletError();
  const onError = useErrorDrawer();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const [NMoTShowDialog, setNMotShowDialog] = useState(false);
  const [NMoTSelected, setNMotSelected] = useState('');
  const [NMoTReleases, setNMotReleases] = useState([]);

  const flatExcelExport = useFlatExcel(anonAddresses);
  const logSentry = useSentry()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOk = (type) => download(type, true);

  const onCancel = (type) => download(type, false);

  const { showSecurePickupDialog } = useSecurePickupDialog(
    {
      onWithoutPincode: onCancel,
      onPincode: onOk
    }, 
    user.features.canFetchPincode
  )

  const { showConfirmDialog } = useConfirmDialog(
    'pincode.confirm.title',
    'pincode.confirm.text',
    onOk,
    onCancel,
    'getpincode.fetch.confirm',
    'getpincode.fetch.skip',
    true
  );

  const getTermsAndConditions = async (blNumber) => {
    const { data: termsData } = await fetchTermsAndConditions(blNumber, anonAddresses);
    return termsData;
  };

  const fetchReleases = async () => {
    return await Http.post('/releases/download/get', {
      filter: JSON.stringify(filter),
      search,
      selectedContainerAddresses,
      // too big for headers or params, could trigger a 413 Entity Too Large response error (SCRDEV-421)
      includeadr: anonAddresses.join(','), 
     });
  };

  const download = async (fileType, fetchPincode) => {
    let bills = [];
    let promises = [];
    // We're going to use the wallet here to get the pincode, so check the token already here
    if (fetchPincode && !isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      return;
    }

    try {
      showProgressDialog();
      let { data } = await fetchReleases();
      console.log("fetchReleases", data)

      if (!data.length) {
        throw new Error('No data found.');
      }

      if (user.features.canNmot && fetchPincode) {
        setNMotReleases(data.map(r => r.address));
        setNMotShowDialog(true);
      }
      
      if (fetchPincode) {
        enqueueSnackbar(
          <div>
            {translate('pincode.retrieve.pins.progress')}
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </div>,
          { persist: true }
        );
      }

      if (fileType === 'flat-xlsx') {
        await flatExcelExport(data, fetchPincode, user.features.canFetchPincode);
      } else {
        data.forEach((bill) => {
          let getBillData = async () => {
            let terms = await getTermsAndConditions(bill.blNumber);
            let releases = await getReleases(bill, fetchPincode);

            return {
              blNumber: bill.blNumber,
              terms,
              releases,
            };
          };
          promises.push(getBillData);
        });

        for (let index = 0; index < promises.length; index++) {
          let bill = await promises[index]();
          bills.push(bill);
        }

        if (fileType === 'pdf') {
          const downloadResponse = await Http.post(
            '/releases/download', 
            {  fetchPincode, bills }, 
            { responseType: 'blob' }
          );

          const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));

          const link = document.createElement('a');
          link.href = url;
          link.id = 'pdf-id';
          link.setAttribute('download', `Releases.${fileType}`);
          document.body.appendChild(link);
          link.click();
        }

        if (fileType === 'xlsx') {
          const workbook = getExcelWorkbook(bills, fetchPincode);
          const buffer = await workbook.xlsx.writeBuffer();
          saveAs(new Blob([buffer]), 'Releases.xlsx');
        }
      }
    } catch (e) {
      logSentry(e);
      if (e.response) {
        onError(e.response.status, e.response.statusText, translate('general.error'));
      } else {
        onError(500, e.message, translate('general.error'));
      }
    } finally {
      if(fetchPincode) { 
        closeSnackbar();
        refreshBills();
      }
      hideProgressDialog();
    }
  };

  const startDownload = async (type) => {
    // see if there is anything else used as filter than 'valid'
    const localFilters = Object.entries(filter).filter(([key, value]) => key !== 'valid' && value !== "" )
    if (["", null, undefined].includes(search) && localFilters.length === 0 && selectedContainerAddresses.length === 0) {
      onError(500, translate(doGrouping ? 'download.error.noselection.grouped' : 'download.error.noselection'), translate('general.error'));  
    } else {
      let { data } = await fetchReleases();

      // 'hasAssignLocations' was used before to trigger useSecurePickupDialog - but this Dialog is only required when 'isSecurePickup'. 
      // Still keeping this line in case it's needed again later on
      // const hasAssignLocations = data.some(b => b.releases.some(r => r.assign_locations.length > 0));
      const isSecurePickup = data.some(b => b.releases.some(r => r.assign_locations.some(al => al.isSecurePickup)));
      
      if (!user.features.canTransferRevoke) {
        isSecurePickup ? showSecurePickupDialog(type) : onOk(type);
      } else { 
        isSecurePickup ? showSecurePickupDialog(type) : showConfirmDialog(type);
      }
    }
  };

  const getReleases = async (bill, fetchPincode) => {
    try {
      let pincodeResults = [];
      
      if (fetchPincode) {
        const assignLocationsCheckResult = await checkAssignLocations({ releaseAddresses: bill.releases.map(r => r.address), anonAddresses });

        const getPincodesForReleases = bill.releases
          .filter(r => filterNonblockedReleases(r))
          .filter(r => filterAssignLocationsReleases(assignLocationsCheckResult, r, user.features.canFetchPincode))
          .map(({address, version}) => ({address, version}));

        pincodeResults = await getPincode(getPincodesForReleases, user.organization);

        pincodeResults = pincodeResults
          .concat(
            // SCRDEV-1973: no pins for blocked containers - add replacement string for blocked containers
            bill.releases
              .filter(r => !filterNonblockedReleases(r))
              .map(r => ({ address: r.address, error: 'Not allowed to fetch pin (blocked)'}))
          )
          .concat(
            // SCRDEV-2200: no pins for unassigned containers - add replacement string for these containers
            bill.releases
              .filter(r => !filterAssignLocationsReleases(assignLocationsCheckResult, r, user.features.canFetchPincode))
              .map(r => ({ address: r.address, error: user.features.canFetchPincode ? 'Must assign to fetch pin' : 'Inactive "Fetch Pincode" subscription'}))
          )
      }

      return bill.releases.map((release) => {
        let pincodeResult = pincodeResults.find(res => res.address === release.address)
        return {
          ...release,
          releaseStatus: translate(`releaseStatus.${release.releaseStatus}`),
  	      pincode: pincodeResult ? (pincodeResult.pincode ? pincodeResult.pincode : pincodeResult.error) : undefined
        };
      });
    } catch (e) {
      onWalletError(translate(e.message));
    }
  };

  return (
    <React.Fragment>
      { doGrouping && (
        <Tooltip title={translate('dataTable.controls.filterTable')}>
          <IconButton
            aria-label="filter"
            onClick={() => { onFilterClick(); window.addToDataLayer('filter', 'open'); }}
            className={classes.icon_hover}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      { user.organizationRole !== 'carrier' && (
        <React.Fragment>
          <Tooltip title={translate('dataTable.controls.pdf')}>
            <IconButton
              aria-label="download"
              className={classes.icon_hover}
              onClick={() => { startDownload('pdf'); window.addToDataLayer('download', 'pdf'); }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={translate('dataTable.controls.downloadExcel')}>
            <IconButton
              aria-label="excel"
              aria-controls="excel-menu"
              aria-haspopup="true"
              onClick={(e) => { handleClick(e); window.addToDataLayer('download', 'excel'); }}
              className={classes.icon_hover}
            >
              <Icon path={ExcelIcon} size={1} color='grey'/>
            </IconButton>
          </Tooltip>
          <Menu
            id="excel-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { startDownload('xlsx'); window.addToDataLayer('download', 'xlsx'); }}>Grouped by BL</MenuItem>
            <MenuItem onClick={() => { startDownload('flat-xlsx'); window.addToDataLayer('download', 'flat-xlsx');}}>Flat table</MenuItem>
          </Menu>
        </React.Fragment>
      )}

      { user.organizationRole === 'carrier' && (
        <CreateReleaseButton
          classes={classes}
          user={user}
          refreshBills={refreshBills}
          doTransfer={doTransfer}
        />
      )}
      
      { user.features.canRequestForRelease && (
        <RequestForReleaseButton
          user={user}
        />
      )}

      { user.organizationRole !== 'carrier' && (
        <BulkOperationsButton
          classes={classes}
          user={user}
          refreshBills={refreshBills}
          doTransfer={doTransfer}
        />
      )}
      
      <NMoTDialog 
        show={NMoTShowDialog}
        selected={NMoTSelected}
        selectedReleaseAddresses={NMoTReleases} 
        onSelection={(selected) => { setNMotSelected(selected) }} 
        // onCancel={() => setNMotShowDialog(false)}
        onCancel={(event, reason) => { if (!['escapeKeyDown', 'backdropClick'].includes(reason)) { setNMotShowDialog(false) } }}
      />

    </React.Fragment>
  );
};

export default DownloadButtons;
