import app from '../feathers';
import logSentry from './logSentry';

const getPincode = async (releaseAddresses, organizationName) => {

  const pincodeResults = await window.walletApi.getPincodeForReleases( releaseAddresses, { from: organizationName } )

  if (pincodeResults) {
    const pincodeSuccesses = pincodeResults.filter(res => res.pincode).map(res => ({releaseAddress: res.address}))

    if (pincodeSuccesses.length) {
      try {
        await app.service('pincode-log').create(pincodeSuccesses);
      } catch (e) {
        logSentry(e);
      }
    }

    return pincodeResults;
  } else {
    throw new Error("getpincode.error.general")
  }
};

const filterSelectedReleases = (selectedReleaseAddresses, release) => {
  return selectedReleaseAddresses.length > 0 ? selectedReleaseAddresses.includes(release.releaseAddress || release.address) : true
}

// SCRDEV-1973: no pins for blocked containers
const filterNonblockedReleases = (release) => {
  return release.releaseStatus.toLowerCase() !== "blocked";
}

const filterAssignLocationsReleases = (assignLocationsCheckResult, release, userCanFetchPincode) => {
  const alr = assignLocationsCheckResult.data.find(r => r.address === release.releaseAddress || release.address);
  // console.log("filterAssignLocationsReleases", alr)
  if (alr) {
    if (alr.is_secure_pickup && !userCanFetchPincode) {
      return false;
    }

    if (alr.required_transfer_status) {
      return alr.required_transfer_status.toLowerCase() === release.transferStatus.toLowerCase();
    }
  } 
  
  return true;
}

export { 
  getPincode,
  filterSelectedReleases,
  filterNonblockedReleases,
  filterAssignLocationsReleases,
}
