/* eslint-disable import/no-named-as-default */
import ReleasesOverview from './ReleasesOverview';
import RequestForReleaseOverview from './RequestForReleaseOverview';
import OrderPremium from './OrderPremium';
import BargesOverview from './BargesOverview';
import TrainsOverview from './TrainsOverview';
import DriversOverview from './DriversOverview';
import CreateTicket from './CreateTicket';
import Settings from './Settings';
import Loader from '../components/Loading';
import DocumentsOverview from './DocumentsOverview'
import Config from "../config.json";
import SecurePickupContainersOverview from './SecurePickupContainersOverview';

export const ROUTES = [
  {
    path: 'order',
    component: OrderPremium,
    helpPage: 'order.html',
    title: 'navbar.order',
    category: 'profile',
    isProtected: true,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'order');
    },
  },
  {
    path: 'releases',
    component: ReleasesOverview,
    helpPage: 'releasepage.html',
    title: 'navbar.overview',
    category: 'profile',
    isProtected: true,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'releases');
    },
  },
  {
    path: 'requests-for-release',
    component: RequestForReleaseOverview,
    isProtected: true,
    title: 'req.for.release.navbar',
    category: 'profile',
    requiresFeature: 'canRequestForRelease',
    // helpPage: 'request-for-releasepage.html',
    onClick: () => {
      window.addToDataLayer('profilemenu', 'request-for-release');
    },
  },
  { 
    path: 'barges', 
    component: BargesOverview, 
    isProtected: true, 
    title: 'navbar.barges',
    category: 'profile',
    requiresFeature: 'canAssignBarge',
    onClick: () => { window.addToDataLayer('profilemenu', 'barges') },
  },
  { 
    path: 'drivers', 
    component: DriversOverview, 
    isProtected: true, 
    title: 'navbar.drivers',
    category: 'profile',
    requiresFeature: 'canAssignDriver',
    onClick: () => { window.addToDataLayer('profilemenu', 'drivers') },
  },
  { 
    path: 'trains', 
    component: TrainsOverview, 
    isProtected: true, 
    title: 'navbar.trains',
    category: 'profile',
    requiresFeature: 'canAssignTrain',
    onClick: () => { window.addToDataLayer('profilemenu', 'trains') },
  },
  {
    path: 'settings',
    component: Settings,
    helpPage: 'settings.html',
    title: 'navbar.settings',
    category: 'profile',
    isProtected: true,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'settings');
    },
  },
  { path: 'loading', component: Loader },
  {
    path: '',
    component: ReleasesOverview,
    helpPage: 'releasepage.html',
    title: 'navbar.overview',
    // category: 'top',
    isProtected: true,
  },
  {
    path: '/',
    adminOnly: true,
    title: 'navbar.govApp',
    category: 'profile',
    isProtected: true,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'govapp');
      window.open(process.env.REACT_APP_GOV_APP_LINK);
    },
  },
  {
    path: 'documents',
    title: 'navbar.documents',
    category: 'profile',
    isProtected: true,
    requiresFeature: 'canDocuments',
    component: DocumentsOverview,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'documents');
    },
  },
  {
    path: 'secure-pickup-containers',
    title: 'navbar.securePickupContainers',
    category: 'profile',
    isProtected: true,
    component: SecurePickupContainersOverview,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'secure-pickup-containers');
    },
  },
  {
    path: '/',
    title: 'navbar.logout',
    category: 'profile',
    onClick: () => {
      window.addToDataLayer('profilemenu', 'logout');
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace(Config.navHomePath)
      // window.location.reload();
    },
  },
  {
    path: 'ticket',
    component: CreateTicket,
    isProtected: true,
    onClick: () => {
      window.addToDataLayer('profilemenu', 'ticket');
    },
  },
];
