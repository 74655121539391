import { useEffect, useState } from 'react';
import client from '../feathers';
import { getAnonAddresses } from '../util/connections';
import { isTokenValid } from '../util/token';
import useWalletError from '../hooks/useWalletError';
import useAuth from '../hooks/useAuth';
import useIntl from '../hooks/useIntl';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import useSentry from '../hooks/useSentry';

export const useBills = (filter, search, doRefreshBills, showBackdrop, doGrouping) => {
  const [state, setState] = useState({
    bills: undefined,
    anonAddresses: [],
  });

  const onWalletError = useWalletError();
  const { user } = useAuth();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { translate } = useIntl();
  const logSentry = useSentry();

  const fetchBills = async () => {
    if (!isTokenValid()) {
      onWalletError(new Error(translate('session.expired')));
      return;
    }

    let { anonAddresses } = state;
    if (anonAddresses.length === 0) {
      try {
        anonAddresses = await getAnonAddresses(user);
      } catch (error) {
        onWalletError(error);
      }
    }

    const { data } = await client.service('bills-of-lading').find({
      query: {
        addresses: anonAddresses,
        doGrouping: doGrouping,
        $filter: filter,
        $search: search
      },
    });
    // console.log("data", data)
    setState({ ...state, anonAddresses, bills: data });
  };

  useEffect(() => {
    try {
      showBackdrop && showProgressDialog();
      doRefreshBills && fetchBills();
    } catch (e) {
      logSentry(e);
      onWalletError(e);
    } finally {
      showBackdrop && hideProgressDialog()
    }
  }, [filter, search, doRefreshBills, doGrouping]);

  useEffect(() => {
    console.log("anonAddresses", state.anonAddresses)
  }, [state.anonAddresses]);

  return { ...state };
};
