/* eslint-disable no-case-declarations */
import React, { useEffect, useReducer, useMemo, useRef, useState } from 'react';
import _, { startCase } from 'lodash';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classNames from 'classnames';
import { debounce } from 'debounce';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import client from '../feathers';
import TMDataTable from '../lib/Table/TMDataTable';
import Containers from './Containers';
import ChartCanvas from './ChartCanvas';
import PincodeDialog from './PincodeDialog';
import useAuth from '../hooks/useAuth';
import useIntl from '../hooks/useIntl';
import DownloadButtons from './DownloadButtons';
import useDrawer from '../hooks/useDrawer';
import { FilterForm } from './FilterForm';
import useTermsDialog from '../hooks/useTermsDialog';
import { useSnackbar } from 'notistack';
import SplitButton from '../components/SplitButton';
import TransferRevokeDialog from './TransferRevokeDialog';
import BlockDialog from './BlockDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import BlockReport from './BlockReport';
import useConfirmDialog from '../hooks/useConfirmDialog';
import useSecurePickupDialog from '../hooks/useSecurePickupDialog';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useBills } from '../hooks/useBills';
import useContainersOptions from '../hooks/useContainersOptions';
import ReleasesTransporters from './releases-transporters/ReleasesTransporters';
import useTransferRevoke from '../hooks/useTransferRevoke'; 
import useLocalFilter from '../hooks/useLocalFilter';
import { removeSpecialCharacters } from '../util/release';
import useSentry from '../hooks/useSentry';
import NMoTDialog from './NMoT/NMoTDialog';
import { types as NMoTTypes } from './NMoT/types';
import AuditTrailDialog from './AuditTrailDialog';
import { getConnectedOrganizations } from '../util/connections';
import { formatDate, formatIsoDate } from '../util/date';
import TabsUI from './TabsUI';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GreenLightsPopover from './GreenLightsPopover';
import { findOrgName } from '../util/connections';
import Chip from '@material-ui/core/Chip';
import TMDialog from '../lib/Dialog/TMDialog';
import { getFormFields } from '../util/release';
import SRPForm from './SRPForm';
import { useTerminals } from '../hooks/useTerminals';
import useCarrierApiError from './carrier-ui/hooks/useCarrierApiError';
import { isTokenValid } from '../util/token';
import useWalletError from '../hooks/useWalletError';
import useTurnInHeaders from '../hooks/useTurnInHeaders';
import { useDrivers } from '../hooks/useDrivers';
import { useBarges } from '../hooks/useBarges';
import Config from '../config.json';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  canvaspie: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0px',
    zIndex: 120,
  },
  canvasStyleSm : {
    left:'0',
    top:'15px',
    position:'absolute',
  },
  canvasStyleLg : {
    left:'27%',
    top:'0',
    position:'absolute',
  },
  headingDiv: {
    backgroundColor:'#fff', 
    position: 'sticky',
    top: '50px',
    zIndex: 111,
  },
  dashboard: {
    position: 'sticky',
    top: '0px',
    zIndex: 120,
  },
  content: {
    margin: 0,
  },
  showSearchText: {
    margin:'-40px 0 0 0',
    fontWeight:500, 
    fontSize:'1rem',
    position:'relative', 
    minHeight:'44px',
    overflow:'auto',
    backgroundColor:"#fff", 
    zIndex:'1200',
    marginLeft:'20px',
    top:'60px', 
    width:'57%'
  }
}));
const ReleaseOverview = (props) => 
{
  const reducer = (state, action) => {
    switch (action.type) {

      case 'SET':
        return { ...state, ...action.payload }
      
      case 'UNSET_BL_NUMBER':
        return { ...state, pincodeBlNumber: null, showPincode: true }

      case 'SET_HIDDEN_COLUMNS':
        return { ...state, hiddenColumns: action.payload }

      case 'SET_PREF_OPTIONS': 
        return { ...state, prefOptions: action.payload }

      case 'SET_COLUMNS':
        return { ...state, columns: action.payload }

      case 'SEARCH':
        // also close all open rows
        return { ...state, search: action.payload, rowsExpanded: [], doRefreshBills: true }

      // This filter only uses 'validity' and is sent to the backend
      case 'SET_FILTER':
        return { ...state, filter: JSON.stringify(action.payload), doRefreshBills: true }
      
      // This filter is used on the data when it comes back from the backend using the filter above
      // and then locally filters on everything except the validity (so vessel etc)
      case 'SET_LOCAL_FILTER': 
        return {...state, local: action.payload}

      case 'SET_ROWS_EXPANDED':
        return { ...state, rowsExpanded: action.payload }

      case 'SET_ANON_ADDRESSES':
        return { ...state, anonAddresses: action.payload }

      case 'SET_TRANSPORTERS':
        return { ...state, transporters: { ...action.payload } };

      case 'REFRESH_BILLS': 
        const { doRefreshBills, showBackdrop } = action.payload
        return {...state, doRefreshBills, showBackdrop: showBackdrop ? showBackdrop : true}
      
      case 'SET_CONTAINERS_OPTIONS_STATE':
        return {...state, containersOptionStateByBl: action.payload}

      case NMoTTypes.SHOW_NMoT:
        return {...state, NMoT: {dialog: true}}
      
      case NMoTTypes.CANCEL_NMoT:
        return {...state, NMoT: {selected: '', dialog: false}}
      
      case NMoTTypes.SET_NMoT:
        return {...state, NMoT: {...state.NMoT, selected: action.payload}}
      
      case 'RESET':
        return {...state, editFormData: {...action.payload}}

      default:
        return { ...state }
    }
  }
  const editFormData = {
    portOfLoading: '',
    portOfDestination: '',
    vessel: '',
    stayNumber: '',
    lloydsNumber: '',
    voyageNumber: '',
    pickupLocation: '',
    validUntil: '',
    turnInLocation: '',
    turnInReference: '',
    turnInLocationBarge: '',
    turnInReferenceBarge: '',
    turnInLocationRail: '',
    turnInReferenceRail: ''
  }

  const initialState = {
    total: undefined,
    data: [],
    containersByBl: {},
    containersOptionStateByBl: {},
    rowsExpanded: [],
    pincodeBlNumber: null,
    showPincode: true,
    columns: [],
    hiddenColumns: ['portOfLoading', 'portOfDestination'],
    prefOptions: {},
    search: '',
    filter: JSON.stringify({valid: 'showOnlyValid'}),
    local: {
      filter: {}
    },
    termsAccepted: true,
    showTransferRevokeDialog: false,
    opType: 'transfer', //transfer or revoke,
    showBlockDialog: false,
    blockStatus: false, // true=block, false=unblock
    blockBlNumber: '',
    showAuditTrailDialog: false,
    selectedReleaseAddresses: [],
    anonAddresses: [],
    connectedOrganizations: [],
    connections: [],
    transporters: {
      dialog: false,
      type: 'assign'
    },
    doRefreshBills: true,
    showBackdrop: true,
    NMoT: {
      dialog: false,
      selected: ''
    },
    doGrouping: true,
    showSelected: false,
    rowsSelected:[],
    containersToGetPincode:[],
    editFormData: Object.assign({}, editFormData),
  }
  
  const querySearch = new URLSearchParams(props.location.search).get('bl');
  const rowsExpanded = querySearch ? [0] : [];
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    search: querySearch,
    rowsExpanded
  });

  // This function is defined here, because it needs to be passed to useTurnInHeaders
  const setColumns = () => {
    // in case the organization has at least 1 BL that is allSecurePickup, we show the column
    // if no BLs are present that are allSecurePickup, we hide the column
    const orgHasAssignLocations = billsOfLading ? billsOfLading.some(b => b.assignLocationOrder !== 999) : false;

    const checkDisplay = (c) => {
      if (state.hiddenColumns.includes(c.name) || (c.name === 'pickupMethod' && !orgHasAssignLocations)) {
        return { ...c, options: { ...c.options, display: 'false' } }; 
      } else {
        return { ...c, options: { display: 'true', ...c.options } }; 
      }
    }

    dispatch({ type: 'SET_COLUMNS', payload: state.doGrouping ? tableColumns.map(checkDisplay) : flatTableColumns.map(checkDisplay) })
  }

  const { translate } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { showComponent, closeDrawer } = useDrawer();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const addressesRef = useRef({});
  const classes = useStyles();
  const { user } = useAuth();
  const [showDashboard, setShowDashboard] = useState(!user.features.canDashboard);
  const [containersFlatView, setContainersInFlatView] = useState([]); //all selected containers on flat view with full data
  const [selectedContainerAddresses, setContainersAddresses] = useState([]); //addresses of selected containers on flat view
  const { bills: billsOfLading, anonAddresses } = useBills(state.filter, state.search, state.doRefreshBills, state.showBackdrop, state.doGrouping);
  const drivers = useDrivers({ is_active: true });
  const barges = useBarges({ is_active: true });
  const containersOptionStateByBl = useContainersOptions(state.containersByBl, containersFlatView, state.doGrouping, drivers, barges);
  const logSentry = useSentry();
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [submitEditLabel, setSubmitEditLabel] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const isCarrier = user.organizationRole === 'carrier';
  const [terminals] = useTerminals();
  const onCarrierApiError = useCarrierApiError();
  const onWalletError = useWalletError();
  const { getTurnInLocationHeader, getTurnInReferenceHeader } = useTurnInHeaders(setColumns);

  const openEditRelease = async () => {
    setOpenEdit(true);
    dispatch({type: 'SET', payload: { editFormData: editFormData}});
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'RESET', payload: { ...state.editFormData, [name]: value } });
  };
  
  //Edit multiple containers for flat view
  const handleEditRelease = async () => {
    showProgressDialog();
    let _editFormData = Object.assign({}, state.editFormData);

    try {
      for (const key in _editFormData) {
        if (_editFormData[key] === '') {
          delete _editFormData[key];
        }
       if (![undefined, null, ''].includes(_editFormData.validUntil)) {
            _editFormData.validUntil = _editFormData.validUntil.split("/").reverse().join("-");
        }
        if (![undefined, null, ''].includes(_editFormData.validFrom)) {
            _editFormData.validFrom = _editFormData.validFrom.split("/").reverse().join("-");
        }
      }
      await client.service('releases').patch(
        null, 
        _editFormData,
        {
          query: { address: { $in: selectedContainerAddresses }}
        }
      );
      hideProgressDialog();
      setOpenEdit(false);
      dispatch({type: 'SET', payload: { editFormData: editFormData}});
      refreshBills(true, false);
    } catch(e) {
      hideProgressDialog();
      onCarrierApiError(e);
    }
  }

  const refreshBills = (doRefreshBills = true, showBackdrop = true) => {
    dispatch({type: 'REFRESH_BILLS', payload: { doRefreshBills, showBackdrop } })
    // remove any selected containers in memory
    // this is probably due a crossover with the selection in containerview
    // selections are kept in memory, but not shown on the screen, so it's confusing, thinking you are for instance revoking an entire BL
    // but in memory only one or a few are 'selected' and thus only one or a few are revoked
    addressesRef.current = {}
    const rowsExpanded = state.rowsExpanded;
    dispatch({ type: 'SET', payload: { selectedReleaseAddresses: [], rowsSelected: [], rowsExpanded: [], showSelected: false } })
    setContainersAddresses([])
    setContainersInFlatView([])
    setShowActionBtn(false);
    dispatch({ type: 'SET', payload: { rowsExpanded } })
  }

  const { onTransferRevoke } = useTransferRevoke(state.selectedReleaseAddresses, refreshBills, state.anonAddresses, dispatch)

  const applyLocalFilter = useLocalFilter(state.doGrouping);
  
  const onOk = async () => {
    try {
      await client.service('users').patch(user.id, { termsAcceptedAt: new Date() })
    } catch (e) {
      //ignore for SCRP-164
    } finally {
      window.location.reload()
    }
  }

  const onFetchPincode = showPincode => async (pincode) => {
    const selectedReleaseAddresses = state.doGrouping ? await getSelectedReleaseAddresses(pincode) : await getSelectedContainerAddress();
    const pincodeBlNumber = state.doGrouping ? pincode : '';
    if (user.features.canNmot && showPincode) {
      dispatch({ type: NMoTTypes.SHOW_NMoT })
    }
    let containersToGetPincode;
    if(!state.doGrouping){
      containersToGetPincode = state.data.filter(r => selectedContainerAddresses.includes(r.address)).map(opt => opt);
    }
    dispatch({ type: 'SET', payload: { pincodeBlNumber, showPincode, selectedReleaseAddresses, containersToGetPincode } })
  }

  const onBlock = async (blockStatus) => {
    const blockAction = blockStatus ? 'block' : 'unblock'
    dispatch({ type: 'SET', payload: { showBlockDialog: false } })
    
    if (user.organizationRole !== 'carrier') {
      return;
    }

    let results, error;
    var addresses = state.doGrouping ? state.selectedReleaseAddresses: selectedContainerAddresses
    try {
      showProgressDialog();
      results = await client.service('releases').patch(
        null, 
        { type: blockAction },
        { query: { address: { $in: addresses }} }
      );
    } catch (e) {
      logSentry(e)
      error = e.message
    } finally {
      hideProgressDialog();
      refreshBills(true, false);

      const successes = results.filter(r => !r.error)
      const errors = results.filter(r => r.error)
      let content;
      if (error) {
        try {
          content = translate('general.error.detail', { error: translate(`backend.error.${error}`) })
        } catch (err) {
          logSentry(error)
          content = `${translate('general.error')}: ${error}`
        }
      } else {
        content = <BlockReport data={{successes, errors}} type={blockAction} />
      }
      showAlert(translate(`blockReport.${blockAction}.summary`), content);
    }
  }

  const { showDialog } = useTermsDialog({ onOk })
 
  const tableColumns = [
    { name: 'containers', options: {display: false,  viewColumns: false, filter: false, print: false, download: false}},
    { name: 'blNumber'},
    { name: 'pickupMethod', 
      options: { 
        filter: false, 
        print: false, 
        download: false,  
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Chip size="small" label={value}/> : <React.Fragment/>
        },
        sortCompare: (order) => {
          return (val1, val2) => {
            return (val1.data - val2.data) * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
    { name: 'portOfLoading' },
    { name: 'portOfDestination' },
    { name: 'vessel' },
    { name: 'agent' },
    { name: 'stayNumber' },
    { name: 'lloydsNumber' },
    { name: 'voyageNumber' },
    { name: '', label: '', options: { viewColumns: false, filter: false, print: false, download: false }}, // action
    ].map(column => ({ ...column, ...column.name && column.name !== 'containers' ? { label: translate(`releaseOverview.columns.${column.name}`) } : undefined }));
  
  //columns for flat table view
  const flatTableColumns = [
    { name: "containerNumber-search", 
      options: { filter: false, download: false, display: false, viewColumns: false } 
    }, 
    'releaseOverview.containers.columns.containerNumber',
    { name: 'pickupMethod', 
      label: translate('releaseOverview.columns.pickupMethod'),
      options: { 
        filter: false, 
        print: false, 
        download: false,  
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Chip size="small" label={value}/> : <React.Fragment/>
        },
        sortCompare: (order) => {
          return (val1, val2) => {
            return (val1.data - val2.data) * (order === 'asc' ? 1 : -1);
          };
        }
      }
    },
    'releaseOverview.columns.vessel',
    'releaseOverview.columns.blNumber',
    { name: 'validUntil', 
      label: translate('releaseOverview.containers.columns.validUntil'),
      options: { 
        sortCompare: (order) => {
          return (val1, val2) => {
            try {
              const date1 = formatIsoDate(val1.data);
              const date2 = formatIsoDate(val2.data);
              const direction = order === 'asc' ? 1 : -1;
              return date1 < date2 ? (-1 * direction) : date1 > date2 ? (1 * direction) : 0;
            } catch (err) {
              return 0;
            }
          };
        }
      }
    },

    { name: translate('releaseOverview.containers.columns.pickupPort'), label: translate('releaseOverview.containers.columns.pickupPort'), options: { display: false } },
    'releaseOverview.containers.columns.pickupLocation',
    getTurnInLocationHeader('Truck'),
    getTurnInLocationHeader('Barge'),
    getTurnInLocationHeader('Rail'),
    getTurnInReferenceHeader('Truck'),
    getTurnInReferenceHeader('Barge'),
    getTurnInReferenceHeader('Rail'),
    'releaseOverview.containers.columns.receivedFrom',
    { name: translate('releaseOverview.containers.columns.releaseStatus'), label: translate('releaseOverview.containers.columns.releaseStatus'), options: { display: false } },
    'releaseOverview.containers.columns.transferStatus',
    'releaseOverview.containers.columns.transferTo'
  ].map(c => typeof c === 'string' ? { name: translate(c), label: translate(c) } : c);
    
  const getSelectedReleaseAddresses = async (blNumber) => {
    const selectedReleaseAddresses = addressesRef.current[blNumber];
    if (selectedReleaseAddresses?.length) {
      return selectedReleaseAddresses.map(r => r.releaseAddress);
    }
    return state.containersByBl[blNumber].map(({releaseAddress}) => releaseAddress);
  }

  // get selected containers from flat view table
  const getSelectedContainerAddress = async() => {
    if(selectedContainerAddresses?.length){
      return selectedContainerAddresses.map(release => release)
    }
  }

  // const transferRevokeOnClick = async (bill, opType) => {
  const transferRevokeOnClick = opType => async (blNumber) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(blNumber) : await getSelectedContainerAddress();
    dispatch({ type: 'SET', payload: { showTransferRevokeDialog : true, opType, selectedReleaseAddresses: addresses } })
  }

  // const assignOnClick = async ({ bill, type }) => {
  const assignOnClick = type => async (blNumber) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(blNumber) : await getSelectedContainerAddress();
    dispatch({ type: 'SET', payload: { transporters: { dialog: true, type }, selectedReleaseAddresses: addresses } })
    // comment in order to copy behaviour of other '*OnClick' methods
    // dispatch({ type: 'SET_TRANSPORTERS', payload: { dialog: true, type } });
  }

  const blockOnClick = async (bill, blockStatus) => {
    if (user.organizationRole === 'carrier') {
      const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress();
      dispatch({ type: 'SET', payload: { showBlockDialog: true, blockStatus, blockBlNumber: bill.blNumber, selectedReleaseAddresses: addresses } })
    }
  }

  const getAuditTrailOnClick = async (bill) => {
    const addresses = state.doGrouping ? await getSelectedReleaseAddresses(bill.blNumber) : await getSelectedContainerAddress()
    dispatch({ type: 'SET', payload: { showAuditTrailDialog: true, selectedReleaseAddresses: addresses } })
  }

  const { showSecurePickupDialog } = useSecurePickupDialog(
    {
      onTransfer: transferRevokeOnClick('transfer'), 
      onAssign: assignOnClick('assign'), 
      onPincode: onFetchPincode(true)
    },
    user.features.canFetchPincode
  )

  const { showConfirmDialog } = useConfirmDialog(
    'pincode.confirm.title', 'pincode.confirm.text', 
    onFetchPincode(true), onFetchPincode(false),
    'getpincode.fetch.confirm', 'getpincode.fetch.skip',
    true
  )

  // This is triggered when the local filter or the data ('billsOfLading', returned by useBills) changes.
  // It runs applyLocalFilter on the 'billsOfLading' returned by useBills
  useEffect(() => {
    var _bills;
    var filterSelectedReleases;
    // billsOfLading is undefined at first, instead of an empty array, to be able to determine whether we are still loading the screen, 
    // or dealing with an empty resultset AFTER the main query (or search) returned nothing.
    if (billsOfLading) {
      if(!state.doGrouping && state.showSelected) {
         filterSelectedReleases  = billsOfLading.filter(release => selectedContainerAddresses.includes(release.address));
        _bills = applyLocalFilter(filterSelectedReleases, state.local.filter);
        dispatch({ type: "SET", payload: { data:_bills, total: _bills?.length || 0 }});
        
      } else {
        _bills = applyLocalFilter(billsOfLading, state.local.filter);
        dispatch({ type: "SET", payload: { 
          data: _bills, 
          // SCRDEV-1211: doRefreshBills was default set to true, so after the first load, set it back to false
          doRefreshBills: false,
          containersByBl: groupBy(_bills, 'blNumber'), 
          total: _bills?.length || 0 
        }})
      }
    }
  }, [billsOfLading, state.local, state.doRefreshBills, state.doGrouping])

  useEffect(() => {
    // only call 'handleRequestForSelectedReleases' when we're in the "view per container"
    !state.doGrouping && handleRequestForSelectedReleases();
  } , [state.showSelected, billsOfLading, selectedContainerAddresses])

  // To show selected releases
  // TODO: this works fine, retaining the selected rows (that selection is broken when using the filter)
  //       look into this method and see if we can use the same approach when filtering
  const handleRequestForSelectedReleases = () => {
    var selected;
    var _bills;
   
    if (state.showSelected) {
      selected =  billsOfLading.filter((release) => {return selectedContainerAddresses?.length && selectedContainerAddresses.includes(release.address)});
      _bills = applyLocalFilter(selected, state.local.filter);
      setTimeout(() => {
        setContainersInFlatView(selected);
      }, 1500)
    } else {
      selected =  billsOfLading.map((release) => {return selectedContainerAddresses?.length && selectedContainerAddresses.includes(release.address)});
      var select =  billsOfLading.filter((release) => {return selectedContainerAddresses?.length && selectedContainerAddresses.includes(release.address)});
      _bills = applyLocalFilter(billsOfLading, state.local.filter);
      setTimeout(() => {
        setContainersInFlatView(select);
      }, 1500)
    }
    const indice = selected.reduce((out, bool, index) => bool ? out.concat(index) : out, [])
    dispatch({ type: "SET", payload: { data:_bills, rowsSelected : indice, total: _bills?.length || 0 }});
  }

  const handlePreferences = async () => {
    const preferences = await client.service('preferences').find();

    let options = preferences.options || {};

    if (!options.limitBL) options.limitBL = Config.defaultLimitBL;
    if (!options.limitCT) options.limitCT = Config.defaultLimitCT;

    dispatch({ type: 'SET_HIDDEN_COLUMNS', payload: preferences.hiddenColumns })
    dispatch({ type: 'SET_PREF_OPTIONS', payload: options })
  }

  //to enable disable submit label while editing multiple containers
  useEffect(() => {
    if(Object.values(state.editFormData).every(x => x === null || x === ''|| x === undefined)) {
      setSubmitEditLabel(false);
    } else {
      setSubmitEditLabel(true);
    }
  } , [state.editFormData])

  useEffect(() => {
    dispatch({ type: 'SET_ANON_ADDRESSES', payload: anonAddresses })
  } , [anonAddresses])

  useEffect(() => {
    dispatch({ type: 'SET_CONTAINERS_OPTIONS_STATE', payload: containersOptionStateByBl })
  } , [containersOptionStateByBl])
  
  // Apply hiddenColumn preferences
  useEffect(() => { 
    handlePreferences() 
  }, [])

  useEffect(() => {
    setColumns()
  }, [state.hiddenColumns, state.doGrouping, billsOfLading])


  // Handle termsAccepted dialog
  useEffect(() => {
    const { termsAcceptedAt, invitedByMsc } = JSON.parse(window.sessionStorage.getItem('org')) || { termsAcceptedAt: null }
    dispatch({ type: "SET", payload: { termsAccepted: invitedByMsc ? !!termsAcceptedAt : true } })
  }, [])

  useEffect(() => {
    showDialog(!state.termsAccepted)
  }, [state.termsAccepted])

  // Handle connected organizations
  useEffect(() => { 
    const handleConnectedOrganizations = async () => {
      if (!isTokenValid()) {
        onWalletError(new Error(translate('session.expired')));
        return;
      }
      const connections = await window.walletApi.getConnections();
      const connectedOrganizations = await getConnectedOrganizations();
      dispatch({ type: 'SET', payload: { connections, connectedOrganizations } })
    }

    handleConnectedOrganizations();
  }, [])

  // auto-refresh with socket
  useEffect(() => { 
    const action = key => (
      <React.Fragment>
        <Button 
           size="small" 
           variant="outlined" 
           style={{color:'#fff',border:'1px solid #fff', marginRight:'10px'}}
           onClick={() => refreshPage()}
        >
          { translate('button.refresh') }
        </Button>
        <Button 
           size="small" 
           variant="outlined"
           style={{color:'#fff',border:'1px solid #fff'}}
           onClick={() => closeSnackbar()}
        >
         { translate('button.dismiss') }
        </Button>
      </React.Fragment>
    );

    client.io.on("release-notification", (arg) => {
      // no notices for carriers
      if (!isCarrier && arg.payload.targetOrganizationAddresses.some(targetAddress => state.anonAddresses.includes(targetAddress)))
      {
        enqueueSnackbar(
          translate(`notification.message.${arg.type}`), 
          {
            variant: 'success',
            persist: true,
            action,
            style:{maxWidth:'420px'}
          }
        );
      }
    })
  }, [state.anonAddresses])


  const refreshPage = async() => {   
    showProgressDialog();
    closeSnackbar();
    setTimeout(() =>  {
      refreshBills();
      hideProgressDialog();
    }, 2000);
  }
   
  const onTransportersClose = () => {
    dispatch({
      type: 'SET_TRANSPORTERS',
      payload: { dialog: false, type: state.transporters.type },
    });
  }

  const groupBy = (data, key) => {
    if (!data?.length) return {}

    return data.reduce((acc, c) => {
      const groupKey = c[key];
      let filteredObj = c.containers || [];

      acc[groupKey]
        ? acc[groupKey].push(...filteredObj)
        : (acc[groupKey] = [...filteredObj]);

      return acc;
    }, {});
  }

  const getActions = bill => {
    const isCarrier = user.organizationRole === 'carrier';
    const securePickups = state.data.filter(c => selectedContainerAddresses.includes(c.address)); 
    // 'hasAssignLocations' was used before to trigger useSecurePickupDialog - but this Dialog is only required when 'isSecurePickup'. 
    // Still keeping this line in case it's needed again later on
    // const hasAssignLocations = state.doGrouping ? bill.assignLocationOrder !== 999 : (securePickups.length ? securePickups.some(c => c.assignLocationOrder !== 999) : false)
    const isSecurePickup = state.doGrouping 
      ? bill.isSecurePickup
      : (securePickups.length ? securePickups.some(c => c.isSecurePickup) : false);

    return <SplitButton 
            disabledBtn={state.doGrouping ? false : !showActionBtn} doGrouping={state.doGrouping} isSecurePickup={isSecurePickup}
            key={state.doGrouping && bill.blNumber} 
            options={[
              isSecurePickup || (!state.doGrouping && selectedContainerAddresses.length === 0) ? { label: "Secure Pick-up" } : {},
              {
                label: translate('blActions.transfer'),
                onClick: () => { transferRevokeOnClick('transfer')(state.doGrouping ? bill.blNumber : ''); window.addToDataLayer('action-btn', 'transfer'); },
                disabled: state.doGrouping ? ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl[bill.blNumber]?.transfer) : 
                          ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl?.transfer)
              }, {
                label: translate('blActions.revoke'),
                onClick: () => { transferRevokeOnClick('revoke')(state.doGrouping ? bill.blNumber : ''); window.addToDataLayer('action-btn', 'revoke'); },
                disabled: state.doGrouping ? ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl[bill.blNumber]?.revoke) : 
                          ((!isCarrier && !user.features.canTransferRevoke) || !state.containersOptionStateByBl?.revoke)
              }, !isCarrier ? {} : {
                label: translate('blActions.block'),
                onClick: () => { blockOnClick(state.doGrouping ? bill : '', true); window.addToDataLayer('action-btn', 'block'); },
                disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.block) : (!state.containersOptionStateByBl?.block),
              }, !isCarrier ? {} : {
                label: translate('blActions.unblock'),
                onClick: () => { blockOnClick(state.doGrouping ? bill : '', false); window.addToDataLayer('action-btn', 'unblock'); },
                disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.unblock) : (!state.containersOptionStateByBl?.unblock),
              }, isCarrier ? {} : {
                  label: translate('blActions.assign'),
                  onClick: () => { assignOnClick('assign')(state.doGrouping ? bill.blNumber : ''); window.addToDataLayer('action-btn', 'assign'); },
                  disabled: (!user.features.canAssignDriver && !user.features.canAssignBarge && !user.features.canAssignTrain) || 
                            (state.doGrouping ? !state.containersOptionStateByBl[bill.blNumber]?.assign : !state.containersOptionStateByBl?.assign),
              }, isCarrier ? {} : {
                  label: translate('blActions.un_assign'),
                  onClick: () => { assignOnClick('un_assign')(state.doGrouping ? bill.blNumber : ''); window.addToDataLayer('action-btn', 'unassign'); },
                  disabled: (!user.features.canAssignDriver && !user.features.canAssignBarge && !user.features.canAssignTrain) || 
                            (state.doGrouping ? !state.containersOptionStateByBl[bill.blNumber]?.unassign : !state.containersOptionStateByBl?.unassign),
              }, isCarrier ? {} :  {
                label: translate('blActions.pincode'),
                onClick: () => {
                  if (!user.features.canTransferRevoke) { onFetchPincode(true)(state.doGrouping ? bill.blNumber : '') }
                  else { isSecurePickup ? showSecurePickupDialog(state.doGrouping ? bill.blNumber : '') : showConfirmDialog(state.doGrouping ? bill.blNumber : '') }
                  window.addToDataLayer('action-btn', 'pincode');
                },
                disabled: state.doGrouping ? (!state.containersOptionStateByBl[bill.blNumber]?.pincode) : (!state.containersOptionStateByBl?.pincode),
              }, {
                  label: translate('blActions.audittrail'),
                  onClick: () => { getAuditTrailOnClick(state.doGrouping ? bill : ''); window.addToDataLayer('action-btn', 'getAuditTrail'); },
                  className: 'showPremium',
              }, !isCarrier || state.doGrouping ? {} : {
                  label: 'Edit',
                  onClick: () => { openEditRelease(); },
              }
            ].filter(o => !!o.label)
            } 
          />
  }

  // Get data to show BL view
  const getData = (bills) => {
    const ret = bills
      .map(bill => [ 
        bill.containers, bill.blNumber, bill.assignLocationLabel, bill.portOfLoading, bill.portOfDestination, bill.vessel, 
        bill.agent, bill.stayNumber, bill.lloydsNumber, bill.voyageNumber, getActions(bill), bill.id
      ])
    return ret;
  }

  // Get data to show flat view
  const getFlatViewData = (bills) => {
    return bills
      .map(bill => {
        let receivedFrom = translate('release.receivedFrom.unknown');
        if (bill.prevParty) {
          if (bill.prevParty.startsWith('address:')) {
            receivedFrom = findOrgName(bill.prevParty.replace('address:', ''), state.connections, state.connectedOrganizations)
          } else {
            receivedFrom = bill.prevParty;
          }
        }

        let containerNumberWithLights = bill.containerNumber;
        if (user.features.canGreenLights && process.env.REACT_APP_GREENLIGHTS_PORTS.split(',').includes(bill.ediLocode)) {
          containerNumberWithLights = <GreenLightsPopover data={bill.greenLights} text={bill.containerNumber}/>
        }

        return [
          bill.containerNumber, // for search
          containerNumberWithLights, bill.assignLocationLabel, bill.vessel, bill.blNumber, formatDate(bill.validUntil), 
          [undefined, null,''].includes(bill.ediName) ? bill.ediLocode : bill.ediName, bill.pickupLocation, bill.turnInLocation, 
          bill.turnInLocationBarge, bill.turnInLocationRail, bill.turnInReference, bill.turnInReferenceBarge, bill.turnInReferenceRail, 
          receivedFrom, bill.releaseStatus ? translate(`releaseStatus.${bill.releaseStatus}`) : '', getTransferStatus(bill), 
          transferTo(bill), bill.id
        ]
      })
  }

  const transferTo = (bill) => {
    let nextParty = "";
    if (bill.transferStatus === "transferred") {
      nextParty = <Chip label="Go Premium" color="primary" size="small" clickable onClick={() => setOpenPremiumDialog(true)}/>
    
      if (user.features.canSeeTransferInfo) { 
        if (bill.nextParty) {
          if (bill.nextParty.startsWith('address:')) {
            nextParty = findOrgName(bill.nextParty.replace('address:', ''), state.connections, state.connectedOrganizations)
          } else {
            nextParty = bill.nextParty;
          }
        } else {
          nextParty = translate('transferStatus.toNextParty');
        }
      }
    }
    return nextParty;
  }

  const getTransferStatus = (bill) => {
    if (bill && bill.transferStatus) {
      let transferStatus = translate(
        bill.transferStatus === 'assigned' && (bill.assignedDriverName || bill.assignedBargeName || bill.assignedTrainName) ? `transferStatus.assigned.info` : `transferStatus.${bill.transferStatus}`, 
        {
          // in case of 'transferStatus.assigned.info':
          type: bill.assignedDriverName 
                  ? translate('driver.title') 
                  : (bill.assignedBargeName 
                      ? translate('barge.title') 
                      : (bill.assignedTrainName 
                          ? translate('train.title') 
                          : ''
                        )
                    ),
                name: bill.assignedDriverName 
                  ? bill.assignedDriverName 
                  : (bill.assignedBargeName 
                      ? bill.assignedBargeName 
                      : (bill.assignedTrainName 
                          ? bill.assignedTrainName 
                          : ''
                        )
                    ),

          visitNumber: bill.assignedVisitNumber ? `(VN ${bill.assignedVisitNumber})` : ''
        }
      );
      return transferStatus;
    } else {
      return '';
    }
  }

  const onFilterClick = () => {
    setShowDashboard(false);
    showComponent(
      <FilterForm
        onFilterChange={onFilterChange}
        filter={{...state.local.filter ,...JSON.parse(state.filter)}}
        onClose={showAll}
      />,
      translate('dataTable.controls.filterTable')
    );
  };

  const showAll = () => {
    onFilterChange({valid: 'showOnlyValid'});
    onDrawerClose();
    // clear the table title
    dispatch({ type: 'SEARCH', payload: '' });
    dispatch({ type: 'SET', payload: { searchMessage: '', searching: false } });
  };
  
  const handleCanvasClick = (dataPoint, chartId, label) =>  {
    // console.debug("dataPoint", dataPoint)
    // console.debug("label", label)
    const dataPoints = { 
      valid: 'showOnlyValid', 
      expired: 'showOnlyInvalid',
      expiresIn1: 'expiresIn1', 
      expiresIn5: 'expiresIn5' 
    };
    // This needs to be in sync with the dashboard query and FilterForm (labels in initialState)
    const chartToFilter = {
      '0': 'releaseStatus',
      '1': 'transferStatus',
      '2': 'pickupLocation',
      '3': 'valid',
      '4': 'portOfDestination', // TODO double-check
    };

    // close the open bills
    dispatch({ type: 'SET_ROWS_EXPANDED', payload: [] })
    dispatch({ type: 'SET', payload: { searching: true } });

    // if a validity is picked, use that. otherwise use 'showBoth', since it does so too in the chart
    let filter = {
      valid: ['valid', 'expired', 'expiresIn1', 'expiresIn5'].includes(dataPoint) ? dataPoints[dataPoint] : 'showBoth'
    };
    let labels = { 
      valid: translate(`valid.${filter.valid}`) 
    };
    
    // Don't overwrite 'valid' - it was set here above
    if (chartId !== '3') {
      // releaseStatus (0) needs to be passed as an array
      filter[chartToFilter[chartId]] = (chartId > 0 ? dataPoint : [dataPoint]);
      labels[chartToFilter[chartId]] = label;
    }
    
    onFilterChange(filter, labels);
  }

  const onFilterChange = async (filter, labels={}) => {
    if (_.isEmpty(labels)) {
      labels = Object.assign({}, filter);
    }

    let searchMessage = [];
    const {valid, ...localFilter} = filter;
    
    // Check if the 'valid' filter value has changed
    // If so, a new search is launched to the backend
    /* SCRDEV-1982: yeet the entire filter to the backend
    if (!_.isMatch(JSON.parse(state.filter), {valid})) {
      dispatch({ type: 'SET_FILTER', payload: _.pick(filter, ['valid'])})
    }
    searchMessage.push(`${translate('filter.field.valid')}: ${translate(`valid.${filter.valid}`).toLowerCase()}`);

    // Then apply the local filter (= anything BUT validity)
    dispatch({ type: 'SET_LOCAL_FILTER', payload: { filter: localFilter } });
    */
    dispatch({ type: 'SET_FILTER', payload: filter })
    // set table title - filter out empty filter values
    if (!_.isEmpty(localFilter)) {
      searchMessage.push(
        ...Object.keys(_.pickBy(localFilter, (f => f.length > 0)))
           .map(f => translate(`filter.field.${f}`) + `: ${labels[f]}`)
      );
    }
    searchMessage = searchMessage.join(', ');
    dispatch({ type: 'SET', payload: { searchMessage, searching: true } });
  };

  const onTransferClose = () => {
    dispatch({ type: 'SET', payload: { showTransferRevokeDialog: false } });
  } 

  const onBlockClose = () => dispatch({ type: 'SET', payload: { showBlockDialog: false } })
  const onAuditTrailClose = () => dispatch({ type: 'SET', payload: { showAuditTrailDialog: false } })
  const onDrawerClose = () => closeDrawer();

  // select dropdown to switch views
  const handleSelectView = (doGrouping) => {
    showProgressDialog();
    dispatch({ type: 'SET', payload: { doGrouping, showSelected: false, rowsExpanded: [], rowsSelected: [], doRefreshBills: true, showBackdrop: false }})
    setContainersAddresses([]);
    setContainersInFlatView([])
    setShowActionBtn(false);
    // reset filter & search
    showAll()

    setTimeout(() => {
      hideProgressDialog();
    }, 2500);
  }
  
  const handleCheck = async() => {
   dispatch({ type: 'SET', payload: { showSelected : !state.showSelected }})
  }

  const table = useMemo(() => 
    <div className="no-radius-table">
      <div className={classNames(classes.headingDiv)}>
          <TabsUI options={[
            { 
              label: translate('releasesOverview.title'), 
              handleSelectView: () => { handleSelectView(true)}
            },
            {
              label: translate('flatView.title'), 
              handleSelectView: () => { handleSelectView(false)},
              className:'iconClass'
            }
          ]}/>
           {!state.doGrouping && user.features.canContainerView && <div className='actionsTabView'>
            <FormGroup style={{display:'inline-block'}}>
                        <FormControlLabel 
                          control={
                            <Checkbox  
                              checked={state.showSelected}
                              color='primary'
                              onChange={() => handleCheck()}
                              />} 
                          label={ translate('show selected containers')} />
            </FormGroup>
          <div style={{width:'auto', display:'inline-block'}}>{getActions()}</div>
        </div>}
        {state.searching
            ? (!user.features.canContainerView && !state.doGrouping ? '' : 
                <h6 className={classNames('showSearchText', classes.showSearchText)} >
                  Showing results for {state.searchMessage}&nbsp;
                  <Button color="secondary" size="small" variant="outlined" onClick={showAll}>{translate('filter.showAll')}</Button>
                </h6>
              )
            : ''}
      </div>
      {!user.features.canContainerView && !state.doGrouping
          ?
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '475px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-view-by-container" />
        :
      <TMDataTable
        className={!state.doGrouping && 'flatViewTable' }
        title={''}
        columns={state.columns}
        data={state.doGrouping ? getData(state.data): getFlatViewData(state.data)}
        options={{
          rowsPerPage: state.doGrouping ? state.prefOptions.limitBL : state.prefOptions.limitCT,
          expandableRows: state.doGrouping ? true : false,
          expandableRowsOnClick: state.doGrouping ? true: false,
          selectableRows: state.doGrouping ? 'none': 'multiple',
          rowsExpanded: state.rowsExpanded,
          rowsPerPageOptions: [10, 50, 150, 300, 500, 1000, 5000, state.data.length>5000 ? state.data.length: ''],
          searchText: state.search,
          search: state.searching ? false : true, //hide search icon
          searchOpen: state.searching ? false : true,
          searchAlwaysOpen: true, 
          selectableRowsOnClick: state.doGrouping ? false : true,
          rowsSelected: state.rowsSelected,
          download: false,
          filter: false,
          print: false,
          textLabels: {
            body: {
              noMatch: state.total === undefined 
                ? translate('dataTable.controls.loading') 
                : (state.total === 0 ? translate('dataTable.controls.noMatch') : translate('dataTable.controls.loading'))
            },
            pagination: {
             rowsPerPage: translate('dataTable.controls.rowsPerPage')
            },
          },
          onRowSelectionChange: (currentRowsSelected, allRowsSelected/*, rowsSelected*/) => {
            const rowsSelected = allRowsSelected.map((r) => r.dataIndex);
            dispatch({ type: 'SET', payload: { rowsSelected } })
            // get address of all selected ones
            const selected = state.data.filter((row, i) => rowsSelected.includes(i));
            setContainersAddresses(selected.map(r => r.address));
            setShowActionBtn(rowsSelected.length>0);
            setTimeout(() => {
              setContainersInFlatView(selected);
            }, 1500)
          },
          customToolbar: () => 
            <DownloadButtons 
              onFilterClick={onFilterClick} 
              filter={{...JSON.parse(state.filter), ...state.local.filter}}
              search={state.search} 
              selectedContainerAddresses={selectedContainerAddresses}
              refreshBills={refreshBills}
              doTransfer={onTransferRevoke}
              anonAddresses={state.anonAddresses}
              doGrouping={state.doGrouping}
            />,
          onTableChange: (action, tableState) => {
            switch (action) {
              case 'columnViewChange':
                const hiddenColumns = tableState.columns.filter(c => c.display === 'false').map(c => c.name);
                dispatch({ type: 'SET_HIDDEN_COLUMNS', payload: hiddenColumns });
                client.service('preferences').create({ hiddenColumns });
                break;
              default:
                return;
            }
          },
          onChangeRowsPerPage: limit => {
            const prefOptions = { ...state.prefOptions, [(state.doGrouping ? "limitBL" : "limitCT")]: limit };
            dispatch({ type: "SET_PREF_OPTIONS", payload: prefOptions });
            client.service('preferences').create({ options: prefOptions });
          },
          customSearch: (searchQuery, currentRow, columns) => {
            const find = (val) => {
              // skip searching in addresses
              if (typeof val === 'string' && val.startsWith("0x")) return false;
              return removeSpecialCharacters(val.toString().toLowerCase()).includes(removeSpecialCharacters(searchQuery.toLowerCase()))
            }
         
            let isFound = false;
            let nestedFound = false;
            const [innerRow] = currentRow;

            for(const val of currentRow) {
              const matchExists = find(val||'');
              if (matchExists) {
                isFound = true;
                break;
              }
            }
          
            state.doGrouping && Array.isArray(innerRow) && innerRow.forEach(obj => {
              for(const val of Object.values(obj)) {
                const matchExists = find(val||'');
                if (matchExists) {
                  nestedFound = true;
                  break;
                }
              }
            })

            dispatch({ type: "SET", payload: { total: (isFound || nestedFound) ? 1 : 0 } });
            return isFound || nestedFound;
          },

          onSearchChange: debounce(text => dispatch({ type: 'SEARCH', payload: text }), 900),

          // Track which rows have been expanded, so we can expand them again after doRefreshBills.
          // Unless search is active, then collapse all rows in case doRefreshBills excludes a BL from the search,
          // because otherwise the table is showing an empty BL (does not match the search, but is still marked as 'expanded')
          onRowExpansionChange: (currentRowsExpanded, allRowsExpanded, rowsExpanded) => {
            const payload = allRowsExpanded.map(r => r.index)
            !state.search && dispatch({ type: 'SET_ROWS_EXPANDED', payload })
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1
            const [containers, blNumber] = rowData
            return (
              <TableRow>
                <TableCell colSpan={colSpan} style={{backgroundColor: '#A89571', padding: '2em 0'}}>
                  <Containers
                    containers={containers}
                    refreshBills={refreshBills} 
                    onContainersSelect={selectedReleases => { 
                      addressesRef.current[blNumber] = selectedReleases;
                    }} 
                    connectedOrganizations={state.connectedOrganizations}
                    connections={state.connections}
                    anonAddresses={state.anonAddresses}
                  />
                </TableCell>
              </TableRow>
            );
          }
        }}
      />}
      
      </div>, [state.data, state.columns, billsOfLading, state.rowsExpanded, 
               state.containersOptionStateByBl, state.doGrouping, showActionBtn]
  )

  return (
    <React.Fragment>
    { 
      <Accordion className={classNames(classes.dashboard)} expanded={showDashboard} onChange={() => {
        if (!showDashboard) { onDrawerClose(); }
        setShowDashboard(!showDashboard);
        window.addToDataLayer('dashboard', showDashboard ? 'open' : 'close')
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dashboard-content"
          id="dashboard-header"
          classes={{ content: classes.content}}
          style={{margin:'0', backgroundColor: '#ddd', flexDirection: 'row-reverse', padding: 0, height: '50px',minHeight:'42px', borderBottom:'4px solid rgba(112,146,184, 0.8)'}}
        >
          <h3 className="showPremium">{translate(`dashboard.show.${showDashboard}`)}</h3>
        </AccordionSummary>
        <AccordionDetails style={{padding: '0'}}>
          <div className={classNames('charts', classes.canvaspie)}>
            { user.features.canDashboard
              ?
                <ChartCanvas showDashboard={showDashboard} anonAddresses={state.anonAddresses} handleCanvasClick={handleCanvasClick}/>
              :
                <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '275px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-dashboard" />
            }
          </div>
        </AccordionDetails>
      </Accordion>
    }
     { table }
     
      <PincodeDialog
        show={state.pincodeBlNumber!==null}  
        blNumber={state.pincodeBlNumber} 
        containers={state.doGrouping ? (state.containersByBl[state.pincodeBlNumber] || []) : state.containersToGetPincode} 
        selectedReleaseAddresses={state.doGrouping ? state.selectedReleaseAddresses : selectedContainerAddresses}
        showPincode={state.showPincode} 
        handleDialogClose={() => {
          dispatch({ type: "UNSET_BL_NUMBER" });
          // refreshBills() didn't do the trick to clear the selection on the table and in memory
          // so we have to repeat it manually here, since the PincodeDialog uses the same Containers component
          // it doesn't seem to re-render and clear the selection once the pincode dialog is closed.
          // Hence we close the Containers row, so it has to re-render when it is reopened
          dispatch({ type: 'SET_ROWS_EXPANDED', payload: [] })
          addressesRef.current = {}
        }} 
        anonAddresses={state.anonAddresses}
        refreshBills={refreshBills}
        connectedOrganizations={state.connectedOrganizations}
        connections={state.connections}
      />
      
      <TransferRevokeDialog 
        show={state.showTransferRevokeDialog} 
        type={state.opType} 
        onTransfer={ async org => { 
          await onTransferRevoke('transfer', org, (state.doGrouping ? state.selectedReleaseAddresses : selectedContainerAddresses));
          if (user.features.canNmot) {
            dispatch({ type: NMoTTypes.SHOW_NMoT });
          }
        }}  
        onRevoke={ async () => {
          await onTransferRevoke('revoke');
        }}
        onCancel={onTransferClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses} 
        anonAddresses={state.anonAddresses}
      />
      
      <BlockDialog
        show={state.showBlockDialog} 
        blockStatus={state.blockStatus} 
        blockBlNumber={state.blockBlNumber}
        onBlock={onBlock} 
        onCancel={onBlockClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses}
      />

      <AuditTrailDialog
        show={state.showAuditTrailDialog} 
        onCancel={onAuditTrailClose} 
        selectedReleaseAddresses={state.selectedReleaseAddresses}
        anonAddresses={state.anonAddresses}
        connections={state.connections}
      />

      <ReleasesTransporters
        transporters={state.transporters}
        onCancel={onTransportersClose}
        selectedReleaseAddresses={state.selectedReleaseAddresses}
        selectedReleaseInfo={state.data}
        refreshBills={refreshBills}
        anonAddresses={state.anonAddresses}
      />

      <NMoTDialog 
        show={state.NMoT.dialog}
        selected={state.NMoT.selected}
        selectedReleaseAddresses={state.selectedReleaseAddresses} 
        onSelection={(selected) => { 
          dispatch({type: NMoTTypes.SET_NMoT, payload: selected})  
        }} 
        // onCancel={() => dispatch({type: NMoTTypes.CANCEL_NMoT})}
        onCancel={(event, reason) => { if (!['escapeKeyDown', 'backdropClick'].includes(reason)) { dispatch({type: NMoTTypes.CANCEL_NMoT}) } }}
      />
     <TMDialog
        key="premium-transfer-info"
        title={translate('premium.transferinfo.title')}
        dialogOpen={openPremiumDialog}
        handleDialogClose={() => setOpenPremiumDialog(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <iframe title='premiumframe' scrolling="no" style={{borderStyle: 'none', width: '100%', height: '350px'}} id="premiumframe" src="https://www.securecontainerrelease.com/iframe-scr-received-from-and-transferred-to" />
      </TMDialog>
      <TMDialog
        key="edit-release"
        title={translate('carrierui.editMultiple.ctr')}
        dialogOpen={openEdit}
        handleDialogClose={() => setOpenEdit(false)}
        maxWidth="md"
        showBottomClose={false}
      >
        <SRPForm
          object={state.editFormData}
          handleChange={handleChange}
          handleSubmit={handleEditRelease}
          submitLabel={translate('carrierui.editMultiple.ctr.label')}
          handleCancel={() => setOpenEdit(false)}
          cancelLabel={translate("general.cancel")}
          fields={getFormFields(terminals, translate, 'edit', 'multiple')}
          needsConfirm={true}
          enableSubmitCondition={submitEditLabel}
          confirmMsg={translate(
            'carrierui.createRelease.section.bl.helperText'
          )}
        />
      </TMDialog>
    </React.Fragment>
  )
}

export default withRouter(ReleaseOverview)
