import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import TMDateInput from '../lib/Input/TMDateInput';

const moment = require('moment');

const DEFAULT_ISOTYPE_CODE = "20DV";

const validateRelease = (release, terminals) => {
  // doesn't work here for some reason, while it does in getFormFields
  // const { translate } = useIntl();
  const now = moment();
  
  const { billOfLading, container } = release;
  
  if (![undefined, null, ''].includes(container.validUntil) && typeof container.validUntil === 'string') { 
    container.validUntil = container.validUntil.split("/").reverse().join("-");
  }
  if (![undefined, null, ''].includes(container.validFrom) && typeof container.validFrom === 'string') { 
    container.validFrom = container.validFrom.split("/").reverse().join("-");
  }
  
  if ([undefined, null, ''].includes(billOfLading.blNumber)) {
    throw new Error("carrierui.validation.missing.bl");
  }
  if ([undefined, null, ''].includes(container.containerNumber)) {
    throw new Error("carrierui.validation.missing.ctrnbr");
  }
  if ([undefined, null, ''].includes(container.pickupLocation)) {
    throw new Error("carrierui.validation.missing.pickup");
  }
  if ([undefined, null, ''].includes(container.validFrom)) { 
    throw new Error("carrierui.validation.missing.validfrom");
  }
  if ([undefined, null, ''].includes(container.validUntil)) { 
    throw new Error("carrierui.validation.missing.validuntil");
  }
  if (moment(container.validUntil).isSameOrBefore(now, 'day')) {
    throw new Error("carrierui.validation.dates.validuntilinpast");
  }
  if (moment(container.validUntil).isSameOrBefore(moment(container.validFrom), 'day')) {
    throw new Error("carrierui.validation.dates.invalidrange");
  }
  if (terminals && !terminals.map(t => t.value ? t.value : t.code).includes(container.pickupLocation.toString())) {
    throw new Error("carrierui.validation.invalid.terminal");
  }
}

const createApiReleaseObjectFromArray = (array, agent, user) => {
  const release = {
    billOfLading: {
      blNumber: array[0],
      portOfLoading: array[1],
      portOfDestination: array[2],
      vessel: array[3],
      agent: agent,
      stayNumber: array[4],
      lloydsNumber: array[5],
      voyageNumber: array[6],
    },
    container: {
      containerNumber: array[7],
      isoTypeCode: ['', null, undefined].includes(array[8]) ? DEFAULT_ISOTYPE_CODE : array[8],
      pickupLocation: array[9],
      turnInLocation: array[10],
      turnInReference: array[11],
      turnInLocationBarge: array[12],
      turnInReferenceBarge: array[13],
      turnInLocationRail: array[14],
      turnInReferenceRail: array[15],
      validFrom: array[16],
      validUntil: array[17],
    }
  }
  if (user) {
    release.billOfLading.creator = user.organizationAddress;
    release.creator = user.organizationAddress;
  }
  return release;
}

const createApiReleaseObjectFromObject = (obj, user) => {
  const release = {  
    billOfLading: {
      blNumber: obj.blNumber,
      portOfLoading: obj.portOfLoading,
      portOfDestination: obj.portOfDestination,
      vessel: obj.vessel,
      agent: obj.agent,
      stayNumber: obj.stayNumber,
      lloydsNumber: obj.lloydsNumber,
      voyageNumber: obj.voyageNumber,
    },
    container: {
      containerNumber: obj.containerNumber,
      isoTypeCode: ['', null, undefined].includes(obj.isoTypeCode,) ? DEFAULT_ISOTYPE_CODE : obj.isoTypeCode,
      pickupLocation: obj.pickupLocation,
      turnInLocation: obj.turnInLocation,
      turnInReference: obj.turnInReference,
      turnInLocationBarge: obj.turnInLocationBarge,
      turnInReferenceBarge: obj.turnInReferenceBarge,
      turnInLocationRail: obj.turnInLocationRail,
      turnInReferenceRail: obj.turnInReferenceRail,
      validFrom: obj.validFrom,
      validUntil: obj.validUntil,
    }
  }
  if (user) {
    release.billOfLading.creator = user.organizationAddress;
    release.creator = user.organizationAddress;
  }
  return release;
}

const getFormFields = (terminals, translate, action, type='single') => {
  return [ 
    { field: '_divider', label: translate('carrierui.createRelease.section.bl'), helperText: translate('carrierui.createRelease.section.bl.helperText') }, 
    type === 'single' ? { field: 'blNumber', label: translate('releaseOverview.columns.blNumber'), disabled: action === 'edit', required: action === 'create' } : {}, 
    type === 'single' ? { field: 'agent', label: translate('releaseOverview.columns.agent'), componentType: Typography } : {},
    { field: 'portOfLoading', label: translate('releaseOverview.columns.portOfLoading') }, 
    { field: 'portOfDestination', label: translate('releaseOverview.columns.portOfDestination') }, 
    { field: 'vessel', label: translate('releaseOverview.columns.vessel') }, 
    { field: 'voyageNumber', label: translate('releaseOverview.columns.voyageNumber') }, 
    { field: 'stayNumber', label: translate('releaseOverview.columns.stayNumber') }, 
    { field: 'lloydsNumber', label: translate('releaseOverview.columns.lloydsNumber') }, 
    { field: '_divider', label: translate('carrierui.createRelease.section.ctr') },
    type === 'single' ? { field: 'containerNumber', label: translate('releaseOverview.containers.columns.containerNumber'), required: type==='multiple' ? false : true } : {},
    type === 'single' ? { field: 'isoTypeCode', label: translate('releaseOverview.containers.columns.isoTypeCode') } : {},
    { field: 'validFrom', label: translate('releaseOverview.containers.columns.validFrom'), componentType: TMDateInput, required: type==='multiple' ? false : true },
    { field: 'validUntil', label: translate('releaseOverview.containers.columns.validUntil'), componentType: TMDateInput, required: type==='multiple' ? false : true },
    { field: 'pickupLocation', fullWidth: true, label: translate('releaseOverview.containers.columns.pickupLocation'), required: type==='multiple' ? false : true, componentType: Select, suggestions: terminals },
    { field: 'turnInLocation', label: translate('releaseOverview.containers.columns.turnInLocation') },
    { field: 'turnInReference', label: translate('releaseOverview.containers.columns.turnInReference') },
    { field: 'turnInLocationBarge', label: translate('releaseOverview.containers.columns.turnInLocationBarge') },
    { field: 'turnInReferenceBarge', label: translate('releaseOverview.containers.columns.turnInReferenceBarge') },
    { field: 'turnInLocationRail', label: translate('releaseOverview.containers.columns.turnInLocationRail') },
    { field: 'turnInReferenceRail', label: translate('releaseOverview.containers.columns.turnInReferenceRail') },

  ]
}

const parseError = string => {
	if (!string) return string
  const possibleRegex = [/with reason '(.*?)'/, /exception during transaction execution: (.*)/]
  let result = null;

  for(const regex of possibleRegex) {
    result = string.match(regex)
    if (result) break;
  }
 
  return result && result[1]
}

const removeSpecialCharacters = (string) => {
  return (typeof string === 'string' ? string.replace(/[^a-zA-Z0-9]/gi, '') : string);
}

const beforeUnloadListener = (event) => {
  // custom text doesn't work anymore, but the confirm IS shown
  event.preventDefault();
  event.returnValue = `Some actions may not complete if you close this window now. Are you sure you want to exit?`;
  return `Some actions may not complete if you close this window now. Are you sure you want to exit?`;
}

export { 
  validateRelease, 
  createApiReleaseObjectFromArray, 
  createApiReleaseObjectFromObject, 
  getFormFields,
  parseError,
  removeSpecialCharacters,
  beforeUnloadListener
}
