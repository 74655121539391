import '../style.css';

import React from 'react';
import TMDataTable from '../../lib/Table/TMDataTable';
import { debounce } from 'debounce';
import useIntl from '../../hooks/useIntl';
import { formatDateTime } from '../../util/date';
import { useSecurePickupContainers } from './hooks/useSecurePickupContainers';
import { AuditTrails } from './AuditTrails';
import { Box } from '@material-ui/core';

const initialState = {
  querySearch: '',
  selectedReleaseAddress: '',
  rowsSelected: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SEARCH': {
      return {
        ...state, selectedReleaseAddress: '',
        rowsSelected: [], querySearch: action.payload
      }
    }
    case 'SELECTED_RELEASE_Address': {
      return { ...state, selectedReleaseAddress: action.payload }
    }
    case 'SET_ROWS_SELECTED': {
      return { ...state, rowsSelected: action.payload }
    }
    default:
      return { ...state }
  }
}

export function SecurePickupContainers() {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });
  const { data = [], doSearch } = useSecurePickupContainers();
  const { translate } = useIntl();

  React.useEffect(() => {
    doSearch(state.querySearch)
  }, [state.querySearch])

  const columns = [
    {
      name: 'bl_number',
      label: 'blNumber',
    },
    {
      name: 'containerNumber',
      label: 'containerNumber',
    },
    {
      name: 'bills_of_lading.agent',
      label: 'agent',
    },
    {
      name: 'pickupLocation',
      label: 'terminal',
    },
    {
      name: 'validFrom',
      label: 'validFrom',
      options: {
        customBodyRender: (date) => formatDateTime(date),
      },
    },
    {
      name: 'validUntil',
      label: 'validTo',
      options: {
        customBodyRender: (date) => formatDateTime(date),
      },
    },
    {
      name: 'release.gateOut',
      label: 'gateOut',
      options: {
        customBodyRender: (date) => formatDateTime(date),
      },
    },
  ].map(({ label, ...rest }) => ({
    label: translate(`scrTerminal.${label}`),
    ...rest,
  }));

  const options = {
    print: false,
    download: false,
    filter: false,
    selectableRows: 'single',
    searchOpen: true,
    searchAlwaysOpen: true,
    enableNestedDataAccess: '.',
    onSearchChange: debounce(text => dispatch({ type: 'SEARCH', payload: text }), 500),
    rowsSelected: state.rowsSelected,
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      const rowsSelected = allRowsSelected.map((r) => r.dataIndex);
      dispatch({ type: 'SET_ROWS_SELECTED', payload: rowsSelected })

      const selected = data.filter((row, i) => rowsSelected.includes(i)).map(r => r.releaseAddress);
      dispatch({ type: 'SELECTED_RELEASE_Address', payload: selected[0] })
    },
  };

  return (
    <div className="no-radius-table">
      <TMDataTable columns={columns} data={data} options={options} />
      <Box sx={{ mt: 2 }}>
        <AuditTrails releaseAddress={state.selectedReleaseAddress} />
      </Box>
    </div>
  );
}
